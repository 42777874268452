import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Icon1 from "../../../assets/Images/WeProvide/Icon1.png";
import Icon2 from "../../../assets/Images/WeProvide/Icon2.png";
import Icon3 from "../../../assets/Images/WeProvide/Icon3.png";
import Icon4 from "../../../assets/Images/WeProvide/Icon4.png";
import Icon5 from "../../../assets/Images/WeProvide/Icon5.png";
import Icon6 from "../../../assets/Images/WeProvide/Icon6.png";
import Aos from "aos";
import "aos/dist/aos.css";
export default function WeProvide() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="weProvide spacing">
          <Row className="weProvideRow">
                <h5 className="text-center">Les enseignements dispensés à l’UPM sont organisés autour de 7 pôles de formation :</h5>
          </Row>
          <Row data-aos="zoom-in">
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon1}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Tourisme, Hôtellerie & Art de Vivre</h6>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon2}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Business & Gouvernance</h6>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon3}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Ingénierie & Innovation</h6>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon4}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Digital, Médias, Arts & Culture</h6>
                </div>
              </div>
            </Col>

            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon5}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Médecine</h6>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxImg">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={Icon6}
                  />
                </div>
                <div className="weProBoxText">
                  <h6>Sport</h6>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
