import React from "react";
import Slider from "react-slick";
import Testimonial2 from "../../../assets/Images/Testimonial/Testimonial2.png";
import Testimonial3 from "../../../assets/Images/Testimonial/Testimonial3.png";
import Testimonial4 from "../../../assets/Images/Testimonial/Testimonial4.png";
const settings = {
  autoplay: true,
  autoplaySpeed: 1500,
  dots: false,
  aroow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
function PeopleSay() {
  return (
    <>
      <div className="spacing">
        <div className="peopleSayBgColor">
          <div className="container">
            <div className="titleFont text-center">
              <h3 className="fw-bold my-5">ILS ONT CHOISI L’UPM ET VOUS EN PARLENT</h3>
            </div>
            <Slider {...settings}>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial2}
                />
                <div className="peopleDetails">
                  <p>
                    Après l’obtention de mon diplôme je me suis consacré à développer ma propre entreprise d’événementiel,
                    j’organise des mariages et fêtes traditionnelles marocaines, mais cela ne m’occupe que les week-ends. En
                    parallèle j’ai donc cherché un poste en entreprise pour développer une vraie carrière managériale. Aujourd’hui
                    je travaille dans une agence de voyage internationale comme assistant directeur réservation pour l’Europe,
                    l’Islande et la Russie. L’UPM m’a donné et permis d’avoir les savoirs et compétences pour en arriver là.
                  </p>
                  <h3>Yazid </h3>
                  <h6>lauréat programme Master grande école</h6>
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial3}
                />
                <div className="peopleDetails">
                  <p>
                      Je suis en première année médecine à la Faculté de Médecine Privée de l’UPM. Depuis ma plus tendre
                      enfance j’ai toujours été très curieux au sujet du corps humain. Lors de mon année de terminale je me suis
                      informé sur la médecine et j’ai été conquis. Ce domaine est très intéressant, tant par la complexité du corps
                      humain que par le bien que peut procurer le fait de sauver des vies. J’ai décidé de poursuivre mon cursus à
                      l’UPM pour la qualité de la formation, des professeurs à l’écoute des questions et des besoins des étudiants.
                      Nous avons des professeurs très compétents, avec une très bonne technique pédagogique et une bonne
                      manière de transmettre les informations essentielles à la bonne poursuite de notre cursus. 
                  </p>
                  <h3>Ilias</h3>
                  <h6>étudiant Médecine</h6>
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial4}
                />
                <div className="peopleDetails">
                  <p>
                      J’ai pour objectif de monter ma propre agence en web marketing. Le digital fait vraiment partie des métiers
                      d’aujourd’hui et encore plus de demain. Grâce à ma formation à l’UPM j’ai développé mes compétences en
                      développement web, applications mobiles, traitement image, vidéo, son et également mes compétences
                      en stratégie digitale. L’UPM m’aide vraiment à tracer mon chemin. La formation est riche et ponctuée
                      d’expériences professionnelles
                  </p>
                  <h3>Hajiba</h3>
                  <h6>étudiante Licence chef de projets multimédias</h6>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeopleSay;
