import React, { useEffect } from "react";
import aboutus from "../../../assets/Images/AboutUs/aboutus.jpg";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
export default function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="aboutus spacing">
          <Row className="aboutRow">
            <Col xl={6} lg={6} md={6} sm={5} xs={12}>
              <img
                className="img-fluid mx-auto"
                alt="AboutUs"
                src={aboutus}
                data-aos="flip-down"
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={7} xs={12} data-aos="fade-up">
              <div className="titleFont">
                <h3>NOTRE VISION</h3>
                <h4 className="fw-bold">
                    L’Université Privée de Marrakech, université reconnue par l’Etat est un acteur majeur dans l’enseignement supérieur au Maroc et en Afrique
                </h4>
              </div>
              <p className="paragraph">
              Les enseignements dispensés à l’UPM sont organisés autour de 7 pôles de formation :
              </p>
              <p>
              La mission de l’UPM est de former les ressources humaines dotées des compétences nécessaires pour performer dans un monde du travail caractérisé par un environnement socio-économique en constante et rapide évolution. Les lauréats de l’UPM sont à même de relever les défis économiques et technologiques du 21ème siècle dans un contexte de mondialisation et d’échanges multiculturels.
              La reconnaissance de l’UPM par l’Etat traduit la qualité de l’offre de formation de l’UPM, en adéquation avec les besoins du marché de l’emploi, le haut niveau d’infrastructure dédiée à l’apprentissage pratique et à l’épanouissement des étudiants.  
              </p>
              {/* <div className="aboutUsPoint">
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Tourisme, Hôtellerie & Art de Vivre</span>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Business & Gouvernance</span>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Ingénierie & Innovation</span>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Médecine</span>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Santé</span>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="aboutCheckPoint">
                      <i className="fas fa-check-circle"></i>
                      <span>Digital, Médias, Arts & Culture</span>
                    </div>
                  </Col>
                </Row>
              </div> */}
              <button className="button btnMore">
                <Link to="/service">Découvrez le programme</Link>
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
