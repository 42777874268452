import React from "react";
import "../../../../src/theme/css/HomePage.css";
import { Link } from "react-router-dom";
import classnames from 'classnames';
import {Row,Container,Col} from 'react-bootstrap'
export default function Home() {
  return (
    <>
      <div className="mainBannerDiv">
        <Container className="FormHeader">
              <Row className="justify-content-center align-items-center">
                <Col md={6}>
                  <h3 className="fs-2 titleHeader">Faites le choix de votre formation Diplômes reconnus par l'État</h3>
                </Col>
                <Col md={6}>
                <form action="" method="POST">
                    <div className="bannerContainTrans formContain">
                      <Container>
                        <Row className="Footerbox">
                            <Col xl={12} lg={12} md={12} className="text-center formContaintitle">
                              <h2 className="text-uppercase">S'inscrire</h2>
                            </Col>
                        </Row>
                        <Row className="Footerbox">
                          <Col xl={12} lg={12} md={12}>
                            <input placeholder="Nom & Prénom"
                             autoComplete="false" name="nomcomplet"/>
                          </Col>
                        </Row>
                        <Row className="Footerbox">
                          <Col xl={12} lg={12} md={12}>
                              <input placeholder="Téléphone" 
                              autoComplete="false" name="phone"
                              />
                          </Col>
                        </Row>
                        <Row className="Footerbox">
                         <Col xl={12} lg={12} md={12}>
                            <input placeholder="Email" name="email" 
                            />
                         </Col>
                        </Row> 
                        <Row className="Footerbox">
                          <Col xl={12} lg={12} md={12}>
                          <input placeholder="Ville" name="ville"
                            />
                          </Col>
                        </Row> 
                        <Row className="Footerbox">
                          <Col xl={12} lg={12} md={12}>
                            <button className="button btnSubmit">
                              Envoyer 
                            </button>
                          </Col>  
                        </Row>
                    </Container>
                </div>
            </form>
                </Col>
              </Row>
        </Container>
      </div>
    </>
  );
}
