import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import OurPricingData from "../HomePage/OurPricingData";
import { Link } from "react-router-dom";

export default function OurPricing() {
  // const [pricingdata, setpricingdata] = useState(OurPricingData);
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container">
        <div className="spacing">
          <div className="titleFont">
            <h3>Our Pricing</h3>
            <h2>Check Our Pricing</h2>
          </div>
          <div className="ourPricingCard ">
            <Row>
              {OurPricingData.map((pricing) => (
                <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                  <div className="pricingCard" data-aos="flip-left">
                    <div
                      className="pricingColorBox text-center"
                      key={pricing.id}
                    >
                      <h5>{pricing.title}</h5>
                      <h3>
                        {pricing.newPrice}
                        <span>{pricing.oldPrice}</span>
                      </h3>
                      <h2>{pricing.subTitle}</h2>
                    </div>
                    <div className="pricingSpacingBox">
                      <ul className="list-unstyled pricingBoxList">
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain1} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain2} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain3} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain4} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain5} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain6} </span>
                        </li>
                      </ul>
                      <div className="text-center">
                        <button className="button">
                          <Link to="/service"> Get Started </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
