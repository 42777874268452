import React from "react";
import FAQ from "../../../assets/Images/Choose/Question.jpg";
import { Row, Col, Accordion } from "react-bootstrap";
function HomeFAQ() {
  return (
    <>
      <div className="container">
        <div className="spacing">
          <Row className="homeFAQRow">
            <Col xl={6} lg={6} md={6}>
              <div className="titleFont">
                <h3>FOIRE AUX QUESTIONS</h3>
              </div>
              <div className="homeFAQ">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    Les diplômes délivrés par l'UPM sont-ils équivalents aux diplômes d'Etat ?
                    </Accordion.Header>
                    <Accordion.Body>
                    L’Université Privée de Marrakech est une université reconnue par l’Etat. Les diplômes LICENCE, MASTER et INGENIEUR délivrés par l’UPM sont équivalents aux diplômes d’Etat.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        Quelles filières du bac sont acceptées à l'UPM ?
                    </Accordion.Header>
                    <Accordion.Body>
                        Que vous soyez titulaire d’un baccalauréat scientifique, technique, économique ou littéraire, vous avez votre place à l’Université Privée de Marrakech.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    Les diplômes délivrés par l’UPM me permettront-ils de travailler dans le public ?
                    </Accordion.Header>
                    <Accordion.Body>
                      L’Université Privée de Marrakech est une université reconnue par l’Etat.
                      Les diplômes LICENCE, MASTER et INGÉNIEUR délivrés par l’UPM sont équivalents aux diplômes d’Etat et vous permettront donc de passer les concours publics et de travailler, si tel est votre souhait, dans le secteur public.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    Est-il possible de faire une partie de notre formation à l’étranger ?
                    </Accordion.Header>
                    <Accordion.Body>
                    L’UPM est ouvert à l’international, grâce à un réseau de partenaires important : des échanges internationaux sont possibles sur tous nos programmes. L’UPM vous offre également la possibilité d’accéder à un double diplôme selon la formation que vous avez choisie.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6}>
              <img className="img-fluid mx-auto" alt="AboutUs" src={FAQ} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default HomeFAQ;
