import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon1 from "../../../assets/Images/Choose/Icon1.png";
import Icon2 from "../../../assets/Images/Choose/Icon2.png";
import Icon3 from "../../../assets/Images/Choose/Icon3.png";
import Icon4 from "../../../assets/Images/Choose/Icon4.png";
import CountUp from "react-countup";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ChooseUs() {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, []);
  return (
    <>
      <div className="chooseBanneImg">
        <div className="container">
          <Row className="align-items-center">
            <h3 className="fw-bold text-center my-3">Quelques chiffres</h3>
            <Col xl={12} lg={12} md={6} sm={12} data-aos="flip-down">
              <Row>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon1}
                      width="70px"
                    />
                    <h3>
                      <CountUp end={60} duration={2.74} />
                    </h3>
                    <h6>programmes de formation Licence, Master, Ingénieur</h6>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon2}
                      width="70px"
                    />
                    <h3>
                      <CountUp end={20} duration={2.75} />
                    </h3>
                    <h6>universités et écoles partenaires dans le monde</h6>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon3}
                      width="70px"
                    />
                    <h3>
                      <CountUp end={7} duration={2.75} />
                    </h3>
                    <h6>pôles d’excellence</h6>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                  <div className="chooseBox text-center">
                    <img
                      className="img-fluid mx-auto"
                      alt="Choose us"
                      src={Icon4}
                      width="70px"
                    />
                    <h3>
                      <CountUp end={30} duration={2.75} />
                    </h3>
                    <h6>ans d’expertise dans l’enseignement supérieur</h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
